import * as android from 'lib/bridge_android'
import * as ios from 'lib/bridge_ios'
import { axios, initializedHeaders } from '../lib/axios'
import { isIOSApp, isApp } from '../lib/platform'
import { rotateScreen } from '../lib/screen'
import { trackEvent } from '../lib/track'

// ActiBookのビューワーはページ送りでhistoryを増やす。ビューワーを閉じたあとにブラウザバックが正しく機能するよう、
// ビューワーを閉じる際に、ビューワーを開く前の history 位置へ巻き戻すようにしている。
// history.go による history の操作では history.length が変わらない。そのため一度ビューワーを閉じたあと、再度
// ビューワーを開いて history.length を参照すると、ビューワーによって先に進められた数字が取れてしまい、期待する
// 動作とならない。ページロード時の index を覚えておき、それを使いまわすようにしている。
// ただしこの対応はアプリでのみ機能させる。ブラウザの場合、ブラウザの使い方次第で戻り先がずれてしまう可能性があるため。
let historyIndexAtPageOpened = null
$(() => {
  if (isApp()) {
    historyIndexAtPageOpened = window.history.length
  }
})

$(document).on('click', '.js-ehon-acti-book-launch-viewer', (e) => {
  const $self = $(e.currentTarget)
  const $modalViewer = $('.js-modal-body-viewer')
  const $html = buildModalBodyViewerContentHtml($self)
  $modalViewer.show()
  $modalViewer.append($html)
  setTrialRequestUrlToSuggestReviewButton($self.data('request-url'))
  return false
})

const handleViewerIframeLoaded = () => {
  rotateScreen('landscape')
}

$(document).on('click', '.js-modal-body-viewer-close', (e) => {
  const viewer = e.currentTarget.closest('.js-modal-body-viewer')
  if (isFirstClickOfModalViewerClose(viewer)) {
    trackEhonViewerClosed(viewer)
    removeLauncher(viewer)
    rollbackHistory()
    rotateScreen('default')
    showAfterViewerView(viewer)
  } else {
    modalBodyViewerClose()
  }
})

$(document).on('click', '.js-ehon-acti-book-all-page-trial-request', (e) => {
  const $self = $(e.currentTarget)
  axios({
    url: $self.data('request-url'),
    method: 'POST',
    headers: initializedHeaders(),
  })
    .then(() => {
      $(`.js-ehon-acti-book-all-page-trial-request-base.js-ehon-acti-book-ehon-no-${$self.data('ehon-no')}`).html(
        $self.data('response-html'),
      )
    })
    .catch(() => {
      alert('エラーが発生したためリクエストできませんでした。しばらく時間をおいてから、もう一度お試しください。')
    })
  return false
})

$(document).on('click', '.js-scroll-to-top', () => {
  $('body, html').animate({ scrollTop: 0 }, 500)
})

$(document).on('click', '.js-ehon-acti-book-click-effect', (e) => {
  rippleClickEffect(e)
})

$(document).on('click', '.js-ehon-acti-book-suggest-review', async (e) => {
  const elem = e.currentTarget
  const requestUrl = elem.dataset.requestUrl
  const trialRequestUrl = elem.dataset.trialRequestUrl
  removeTrialRequestUrlFromSuggestReviewButton()
  const response = await axios({
    url: requestUrl,
    method: 'GET',
    params: {
      trial_request_url: trialRequestUrl,
    },
  })
  const subjectToReviewSuggestion = response.data.subject_to_review_suggestion
  if (!subjectToReviewSuggestion) {
    return
  }

  if (isIOSApp()) {
    ios.postMessage('suggestReview')
  } else {
    $('#js-android-in-app-review-modal').modal('show')
  }
})

$(document).on('click', '.js-android-in-app-review', () => {
  android.call('suggestInAppReview')
})

$(document).on('click', '.js-android-in-app-review-link', () => {
  $('#js-android-in-app-review-modal').modal('hide')
})

$(document).on('click', '.js-sugotoku-contract-status-auth', () => {
  newSugotokuContractStatusAuth()
})

$(document).on('click', '.js-ehon-acti-book-like-button', (e) => {
  const el = e.currentTarget
  const $el = $(el)
  const liked = el.dataset.liked == 'true' // 常に最新の値をとりたいのであえて $el.data を使っていない
  axios({
    url: $el.data('request-url'),
    method: liked ? 'delete' : 'post',
    headers: initializedHeaders(),
  }).then(() => {
    // ここは操作が完了したあとに走る処理であり、liked をそのまま使うと混乱する。コードの意図が分かりやすくなるよう、変数名を変えている
    const removed = liked
    if (removed) {
      $el.removeClass('is-active')
      $el.find('.js-like-icon[data-liked=true]').addClass('d-none')
      $el.find('.js-like-icon[data-liked=false]').removeClass('d-none')
    } else {
      $el.addClass('is-active')
      $el.find('.js-like-icon[data-liked=true]').removeClass('d-none')
      $el.find('.js-like-icon[data-liked=false]').addClass('d-none')
    }

    const trackParams = JSON.parse(el.dataset.trackParams)
    el.dataset.trackParams = JSON.stringify({ ...trackParams, like: !trackParams.like })
    el.dataset.liked = String(!removed)

    const ehonActiBookId = $el[0].dataset.ehonActiBookId
    const $likeIcon = $(`.js-ehon-acti-book-thumbnail__like-icon[data-ehon-acti-book-id=${ehonActiBookId}]`)
    $likeIcon.toggleClass('d-none', removed)
  })
})

const rippleClickEffect = (e) => {
  const x = e.pageX
  const y = e.pageY
  const clickEffect = document.createElement('div')
  clickEffect.style.top = `${y}px`
  clickEffect.style.left = `${x}px`
  document.body.append(clickEffect)
  clickEffect.className = 'ehon-acti-book-click-effect'
  clickEffect.addEventListener(
    'animationend',
    function () {
      this.remove()
    },
    false,
  )
}

const setTrialRequestUrlToSuggestReviewButton = (url) => {
  $('.js-ehon-acti-book-suggest-review')[0].dataset.trialRequestUrl = url
}

const removeTrialRequestUrlFromSuggestReviewButton = () => {
  $('.js-ehon-acti-book-suggest-review')[0].dataset.trialRequestUrl = ''
}

export const modalBodyViewerClose = () => {
  const $modalViewer = $('.js-modal-body-viewer')
  $modalViewer.find('.modal-body-viewer-content').remove()
  $modalViewer.hide()
  return false
}

const findLauncher = (viewer) => {
  return viewer.querySelector('.js-launcher')
}

const isFirstClickOfModalViewerClose = (viewer) => {
  return !!findLauncher(viewer)
}

const trackEhonViewerClosed = (viewer) => {
  const launcher = findLauncher(viewer)
  const actibook = launcher ? launcher.contentDocument.querySelector('.js-acti-book-iframe') : null
  if (!actibook) {
    return
  }

  const params = $(actibook).data('closeTrackParams')
  trackEvent('click_ehon_acti_book_viewer_close', params)
}

const removeLauncher = (viewer) => {
  const launcher = findLauncher(viewer)
  if (launcher) {
    launcher.remove()
  }
}

const showAfterViewerView = (viewer) => {
  $(viewer.querySelector('.js-after-ehon-viewer')).show()
}

const newSugotokuContractStatusAuth = () => {
  ios.postMessage('newSugotokuContractStatusAuth')
}

const buildModalBodyViewerContentHtml = ($el) => {
  const $html = $('<div class="modal-body-viewer-content"></div>')
  const iframe = document.createElement('iframe')
  iframe.classList.add('js-launcher')
  iframe.src = `${$el.data('request-url')}?launch_from_app=${$el.data('launch-from-app')}`
  if ($el.data('ehon-content-type') == 'all_page_trial') {
    iframe.setAttribute('data-screen-capture-prohibit', '')
  }
  iframe.addEventListener('load', handleViewerIframeLoaded)
  $html.append(iframe)

  const tmpl = document.querySelector(
    `.js-after-ehon-viewer-template[data-ehon-acti-book-id="${$el.data('ehon-acti-book-id')}"]`,
  )
  if (tmpl) {
    $html.append(tmpl.innerHTML)
  }

  return $html
}

const rollbackHistory = () => {
  if (historyIndexAtPageOpened) {
    const diff = historyIndexAtPageOpened - window.history.length
    if (diff != 0) {
      window.history.go(diff)
    }
  }
}
