import { onConnect } from '@sonicgarden/onconnect'
import * as android from 'lib/bridge_android'
import * as ios from 'lib/bridge_ios'
import { isIOS, isAndroid } from 'lib/platform'

onConnect('[data-screen-capture-prohibit]', () => {
  toggleScreenCaptureAllowed()

  return () => {
    toggleScreenCaptureAllowed()
  }
})

function toggleScreenCaptureAllowed() {
  const el = document.querySelector('[data-screen-capture-prohibit]')
  const allowed = !el

  if (isIOS()) {
    ios.postMessage('setScreenCaptureAllowed', allowed)
  } else if (isAndroid()) {
    android.call('setScreenCaptureAllowed', allowed)
  } else {
    console.log('setScreenCaptureAllowed', allowed)
  }
}
